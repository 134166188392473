<template>
  <div class="service">
      
       <h5 class="title">platform service agreement</h5>
       <h3 class="title_big">Platform Service Agreement</h3>
       <div class="text_red">in order to protect the rights and interests of users, please carefully read all the contents of this service agreement (hereinafter referred to as "this agreement") before logging on to the platform, especially the provisions that exempt or limit the company's liability (these clauses usually contain words such as "no responsibility", "disclaimer", "no obligation"), the applicable laws and jurisdiction clauses in the event of disputes, and other provisions restricting the rights of users these terms shall apply to the fullest extent permitted by prc law and such provisions shall normally be in red or bold or other similar expressions with emphasis. this agreement applies to the user's use of the services provided by the platform and has the effect of a contract. when the user clicks [accept] online (or other words with the same meaning, such as "agree"), or registers, or uses the services provided by the platform, it is deemed that the user agrees and has signed this agreement, and agrees to abide by all the terms of this agreement. thereafter, the user shall not raise any form of defense on the grounds of failure to read/agree to the content of this agreement or similar grounds.</div>
       <div class="text_red">the formation, performance, interpretation and dispute resolution of this agreement shall be governed by the laws of the people's republic of china and shall be governed by all other conflicts of laws. this agreement was entered into in baoquanling agricultural reclamation, heilongjiang province. if any dispute arises between the parties over the content of this agreement or its implementation (including but not limited to contracts or other property rights and interests disputes), the two parties shall resolve it through friendly consultation, and if the negotiation fails, the parties agree to submit it to the jurisdiction and handling of the court with jurisdiction in the place where this agreement is concluded.</div>
       <h3 class="title_big">article 1 content and validity of the agreement</h3>
       <div class='text_msg'>1.1 this agreement is signed by the user and the company operating the platform (this agreement is "the company").</div>
       <div class="text_red">1.2 the terms set forth in this agreement cannot be completely listed and cover all rights and obligations between the platform and the user, so the body of this agreement and all the rules, usage aids and processes that have been released or will be published by the platform in the future are an inseparable part of this agreement and have the same legal effect as the body of this agreement. when using the services provided by the platform, the user undertakes to accept and comply with the provisions of the relevant rules.</div>
       <div class="text_red">1.3 the company has the right to modify/update the relevant terms of this agreement, platform rules, etc. when necessary, and perform the necessary procedures in accordance with laws and regulations. when using the services of this platform, users shall promptly review and understand the modified content and consciously abide by the relevant terms of this agreement. after the modification of this agreement, if the user continues to use the services of the platform, it is deemed that the user has fully accepted the modification of the terms, otherwise the user shall immediately stop using the platform.</div>
      
       <div class="title_big">article 2 definitions</div>
       <div class="text_msg">2.1 Platform: a third-party online trading platform that provides network business venues, transaction matching, and information release for both parties or parties to independently carry out trading activities for both parties or parties to trade.</div>
       <div class="text_msg">2.2 "Rules": refers to all rules, interpretations, announcements, etc. published or to be released by in the future, as well as all kinds of rules, implementation rules, announcements, notices, Q&A, help, transaction process instructions, etc. published by  in the forum, help center, etc.</div>
       <div class="text_msg">2.3 "user": "you" or "end user", that is, a natural person, legal person or other organization that obtains the company's authorization for non-commercial use through the legal channels provided by the company to install and use the platform software or accept the services of the platform for game jewelry transactions.</div>
       <div class="text_msg">2.4 "Game Accessories" refers to the game characters, game resources, game props, etc. (including but not limited to weapons, mounts, pets, equipment, skins, etc.) in the game provided by steam platform  that can be transferred between different accounts within the platform and can be transferred between different accounts within the platform. This Agreement and the terms "pick-up", "booking", "creating transactions" and other terms described in the relevant pages of the  Platform refer to and only refer to the transfer of the aforementioned property or property interests and their transaction consideration between specific entities.</div>
       
       <div class="title_big">article 3 account registration and use</div>
       <div class="text_msg">3.1 Before using the Platform services, the User must first register to obtain the account and password of the Platform (hereinafter collectively referred to as "Platform Account" or "User Account", "Account"). The ownership of the user account belongs to the platform, and the user completes the registration procedure and obtains the right to use the  account in accordance with this agreement and the rules of the platform.</div>
       <div class="text_msg">3.2 the user agrees and confirms that the platform services are provided to natural persons who have full capacity for civil conduct and can independently bear civil liability in accordance with the laws and regulations of the people's republic of china;
           <span> if the user is a person with limited capacity for civil conduct, he or she must read this agreement accompanied by a legal representative, and only after obtaining the consent of the legal representative to the user's use of the platform services, payment of fees, etc., as well as all the terms of this agreement, can the platform services be used persons without capacity for civil conduct shall not use the services of this platform.</span>
       </div>
       <div class="text_msg">3.3 the user is obliged to provide his true, accurate, current and complete personally identifiable information in accordance with the company's prompts in the registration process; if the user provides any personally identifiable information that is untrue, inaccurate, incomplete or does not reflect the current situation, or the company has reasonable grounds to suspect that such information is untrue, inaccurate, incomplete or does not reflect the current situation, the company reserves the right to refuse or stop the user from using the platform services.
           <span>the user acknowledges and agrees that the true, accurate, current and complete personally identifiable information submitted by the user to the company is the only valid evidence proving the user's association with the platform account registered by the user.</span>
       </div>
       <div class="text_red">3.4 the user understands and agrees that it is the user's responsibility to ensure the confidentiality and security of the account and password. the user shall keep his account password properly and use it correctly and safely, and shall be fully responsible for all acts and all remarks made using his account and password. if the loss and theft of the account password due to poor custody of the user account causes damage to the user or others, the user shall bear the legal liability arising therefrom.</div>
      
       <div class="text_msg">3.5 if the user discovers that a third party has fraudulently used or embezzled its own account or password, or there is any other situation without legal authorization, the user shall immediately notify the platform in a valid manner and provide the platform with personally identifiable information consistent with the registration information of the account involved.</div>
       <div class="text_red">if the above situation occurs in the user account and the user notifies the platform in accordance with the regulations and the platform verifies that the personally identifiable information provided by the user is consistent with the personally identifiable information submitted at the time of registration, or the platform finds that the above situation exists, in order to protect the security of the transaction, the platform has the right to investigate, and has the right to take corresponding measures according to the severity of the circumstances (including but not limited to suspension/termination of the provision of the platform services, cancellation/freezing of orders, freezing/cancellation of transactions, restriction/ it is forbidden to log in to the platform, restrict/freeze the rights and interests of user accounts, freeze/suspend accounts, etc.), and the user does not have the right to claim any responsibility from the platform. if the personally identifiable information is not provided or the personally identifiable information provided is inconsistent with the personally identifiable information submitted at the time of registration, the platform has the right to refuse the user's relevant request, in this case, the company does not assume any responsibility for all the consequences of anyone using the account and password.</div>
       <div class="text_msg">3.6 if required by china's current laws, regulations and normative documents, users should conduct real-name authentication in accordance with the requirements and platform rules.</div>
        <div class="text_msg">3.7 the user account shall not be transferred in any way, unless there is a clear provision of the law, a judicial ruling or with the consent of the platform and the operation is carried out in accordance with the requirements of the relevant procedures of the platform. once the account is transferred, the rights and obligations under the account are transferred at the same time.</div>
       <div class="text_msg">3.8 If the user's account does not violate any laws and regulations or the rules of the platform, if the user applies to cancel the  user account, the user can contact the customer service of the platform and operate according to the requirements. Once the user account is logged out, the user will no longer be able to use the services of the platform, and will not be able to retrieve any content or information related to the user account before the user logs off the account of the platform.</div>
       <div class="text_msg">3.9 if the user does not log in to the platform for 365 consecutive days, the company has the right to take measures to delete any records of the user account on the platform from 24:00 on the 365th day, and the deleted data and information can no longer be recovered.</div>

       <div class="title_big">article 4 user code of conduct</div>
       <div class="text_msg">4.1 the user undertakes that all acts carried out when using the services of the platform shall comply with the requirements of laws and regulations of the people's republic of china and this agreement and the rules of the platform.</div>
       <div class="text_msg">4.2 the user undertakes to abide by the principle of good faith in the process of using the platform to conduct transactions, and shall not engage in fraudulent, false or misleading activities</div>
       <div class="text_msg">4.3 when users publish information on the platform and related web pages or use the services of the platform, they shall not produce, copy, publish or disseminate the following information:</div>
       <div class="text_msg">(1) violating the basic principles established by the constitution of the people's republic of china;</div>
       <div class="text_msg">(2) endangering the national security of the people's republic of china, divulging state secrets, subverting state power, or undermining national unity;</div>
       <div class="text_msg">(3) harming the national honor and interests of the people's republic of china;</div>
       <div class="text_msg">(4) inciting ethnic hatred or ethnic discrimination, undermining national unity;</div>
       <div class="text_msg">(5) undermining the religious policies of the people's republic of china and promoting cults and feudal superstitions;</div>
       <div class="text_msg">(6) spreading rumors, disturbing social order, or undermining social stability;</div>
       <div class="text_msg">(7) spreading obscenity, pornography, gambling, violence, murder, terror, or instigating crimes;</div>
       <div class="text_msg">(8) insulting or slandering others, infringing upon the lawful rights and interests of others;</div>
       <div class="text_msg">(9) contains false, fraudulent, harmful, coercive, invasive of others' privacy, harassment, infringement, slander, vulgarity, obscenity, or other content that is morally objectionable; contains other content restricted or prohibited by chinese laws, regulations, rules, regulations, self-regulatory documents, and any norms with legal effect;</div>
       <div class="text_msg">(10) endangering the legitimate rights and interests of others in any way (including but not limited to stealing other people's bank accounts, cashing out coupons, illegal acts of false transactions, and using technical means to establish false accounts in batches);</div>
       <div class="text_msg">(11) publish, send or otherwise transmit content that infringes the copyright, patent right, trademark right, trade secret, or other exclusive rights of others;</div>
       <div class="text_msg">(12) information that the company has reason to believe that it is not related to the transaction on the platform or is not for the purpose of trading or disrupts the normal trading order;</div>
       <div class="text_msg">(13) publish any other information that the company reasonably judges as inappropriate, or that the company has reason to believe violates the law, the public interest, social order and good customs, or other information that harms the legitimate rights and interests of others;</div>
       <div class="text_msg">(14) contains other content prohibited by laws and administrative regulations of the people's republic of china.</div>
       <div class="text_msg">(14) contains other content prohibited by laws and administrative regulations of the people's republic of china.</div>
       <div class="text_msg">(1) deleting, modifying, or adding computer information network functions without permission;</div>
       <div class="text_msg">(2) deleting, modifying or adding data and applications stored, processed or transmitted in computer information networks without permission;</div>
       <div class="text_msg">(3) deliberately making or disseminating destructive programs such as computer viruses;</div>
       <div class="text_msg">(4) other acts endangering the security of computer information networks.</div>
       <div class="text_msg">4.5 the user shall not interfere with the services of the platform in any way, including but not limited to the development, support or use of software, devices, scripts, programs or the use of any technical means to maliciously damage the website of the platform and interfere with the normal operation of the platform.</div>
       <div class="text_msg">4.6 unless otherwise agreed in this agreement, the user shall not carry out the following acts (whether for profit or non-profit) without the prior written consent of the company:</div>
       <div class="text_msg">(1) copying, ripping, decompiling, disassembling, disseminating and displaying the programs of the platform, user manuals and other graphic audio-visual materials in whole or in part, or reverse engineering any function or program of the platform.</div>
       <div class="text_msg">(2) PUBLICLY DISPLAY AND BROADCAST ALL OR PART OF THE CONTENTS OF THE PLATFORM SERVICES, TRANSACTION PROCESS, ETC. IN VARIOUS FORMS AND ON VARIOUS PLATFORMS (INCLUDING BUT NOT LIMITED TO ONLINE LIVE BROADCASTING PLATFORMS, WEBSITES, APP, ETC.).</div>
       <div class="text_msg">(3) modify or obscure the service name, company logo, trademark or copyright information, other intellectual property declarations, etc. on the platform programs, images, animations, packaging, manuals, etc.</div>
       <div class="text_msg">(4) Violation of the Robots agreement of the Platform or breakthrough of anti-crawler technical measures, illegal acquisition of information on the Platform, and interference with the normal operation of the Platform.</div>
       <div class="text_msg">(5) other acts that violate the trademark law, the copyright law, the computer software protection regulations, relevant laws and regulations, and international treaties.</div>
      
      <div class="title_big">5. platform trading rules</div>
      <!-- <div class="text_red">5.1 as an online trading platform, the platform allows users to trade in a lawful manner and in compliance with laws, this agreement and related rules. the company does not participate in transactions between the parties to the transaction and is not obliged to review the registration data of all users, all transactions and other matters related to the transaction. however, the user agrees that in order to protect and safeguard the interests of the company and other users, the company has the right to supervise the user's use of the software /service.</div>
      <div class="text_red">if the user violates laws/regulations/relevant policies/terms of this agreement/platform rules, or is reported by a third party, or the company determines that the user's account data is abnormal/or abnormal transactions, the company has the right to choose one or more of the following processing measures to eliminate or mitigate the impact of the user's misconduct without notifying the user: restrictions/prohibitions on listing of goods, restrictions/prohibitions on purchases, prohibitions, restrictions/prohibitions on initiating private messages, modification/deletion of user's username/avatar and other user-defined materials, deletion of information posted by users, restrictions/prohibitions on login, suspension/termination of provision of services on the platform, cancellation/freezing of orders, freezing/cancellation of transactions, restrictions/prohibitions on logging into the platform, restrictions/restrictions on user account rights, freezing/blocking of accounts, notifying cooperative third-party payment institutions to deduct equal transaction amounts from the balance of the user's payment account, notify the cooperating third-party payment institutions to restrict or/prohibit the user's right to use the payment account and its balance, and publicize the user's breach/violation/violation on the platform.</div>
      <div class="text_red">in the above case, the user has no right to claim any responsibility from the company, and any legal liability or loss caused thereby shall be borne by the user. the form of legal liability and loss borne by the user includes, but is not limited to: the user shall compensate the company and/or its affiliates for any loss suffered as a result, including but not limited to attorneys' fees, damage compensation costs, government agency penalty costs and other tort compensation costs.</div>
       -->
      <div class="text_red">5.1 As an internal trading platform of the company, this platform is designed to facilitate the exchange of goods and services among employees while ensuring the legality and compliance of transactions. The company will supervise and manage the operation of the platform.Users should comply with the following terms when using the platform for transactions:</div>
      <div class="text_red">Users should conduct transactions in accordance with the law and regulations, and comply with company policies, this agreement, and relevant internal rules. The company will review all users' registration data and transaction activities to ensure the legality and security of transactions. To protect the interests of the company and other users, the company has the right to monitor the behavior of users using the platform and take appropriate measures when necessary. If a user violates laws and regulations, company policies, the terms of this agreement, or platform rules, or is reported by other users, or if the company determines that the user's account data is abnormal or the transaction is abnormal, the company has the right to take one or more of the following measures to eliminate or mitigate the impact of the user's improper behavior   </div>
      <div class="text_red">Restrict or prohibit purchases Prohibit or restrict the initiation of internal communication</div>
      <div class="text_red">Restrict or prohibit login Suspend or terminate services on the platform</div>
      <div class="text_red">Cancel or freeze orders Restrict user account rights Freeze or block accounts Under the circumstances of taking the above measures, users should understand and agree not to claim any liability against the company. Any legal responsibility or loss arising therefrom shall be borne by the user, including but not limited to: Compensate the company and its affiliates for any losses suffered as a result Including attorney fees, compensation costs, government agency fine costs, and other infringement compensation costs When using this platform, users should fully understand and accept the above terms. The company reserves the right</div>
      <div class="title_big">article 6 ownership of intellectual property rights and other rights</div>
      <div class="text_msg">6.1 for all works and materials (including but not limited to text, software, sound, photos, videos, charts, etc.) owned by the company in copyright, patent rights, trademark exclusive rights and other intellectual property rights on the platform, unless expressly authorized by the company in advance, no one shall use them in any form without authorization, otherwise the company has the right to immediately terminate the provision of services to such users and pursue their legal responsibilities in accordance with the law.</div>
      <div class="text_red">6.2 the user warrants that all content published on the platform has obtained legal ownership or legal authorization, and that such content will not infringe the legitimate rights and interests of any third party. the user hereby expressly agrees that by providing the foregoing content, the user irrevocably grants the company and its affiliates a worldwide, perpetual, free license to use, transmit, reproduce, modify, sublicense, translate, publish, perform and display such content, as well as to create derivative works from such content.</div>
      
      <div class="title_big">article 7 privacy policy</div>
       <div class="text_msg">7.1 when the company requests users to provide information related to their personal identity, they shall disclose their privacy protection policies and personal information utilization policies to users in a clear and easy-to-see manner in advance, and take necessary measures to protect the security of users' personal information.</div>
       <div class="text_msg">without the user's permission, the company shall not provide, disclose or share personally identifiable information such as name, personal valid identity document number, contact information, home address and other personally identifiable information in the user's registration data to any third party, except in the following circumstances:</div>
       <div class="text_msg">(1) the user or the user's guardian authorizes the company to disclose;</div>
       <div class="text_msg">(2) the company is required to disclose by relevant laws;</div>
       <div class="text_msg">(3) the judicial or administrative organ requests the company to provide it based on legal procedures;</div>
       <div class="text_msg">(4) when the company files a lawsuit or arbitration against the user in order to safeguard its legitimate rights and interests;</div>
       <div class="text_msg">(5) when the user's personally identifiable information is provided at the lawful request of the user's guardian.</div>
       <div class="text_red">In order to protect the privacy of users and regulate the use of users' personal information, the Company has formulated the "Platform Privacy Policy" , and asks users to read the contents of this policy in detail.</div>
       <div class="text_msg">7.2 in addition to the aforementioned personal information, you agree and authorize the company to collect other necessary information from you based on the requirements of administrative/judicial authorities or security, user experience optimization and other considerations, but the company may only collect and use such information for the purpose of performing this agreement.</div>
      
      <div class="title_big">article 8 modification, suspension and termination of services</div>
       <div class="text_red">8.1 the company reserves the right to modify or interrupt the services of the platform at any time without prior notice to the user.</div>
       <div class="text_red">8.2 for the normal operation of the website, the company needs to regularly carry out downtime maintenance of the website or emergency shutdown maintenance for emergencies, and the user shall understand the temporary interruption of normal services caused by the above circumstances, and huawei shall be obliged to do its best to avoid service interruptions and limit the interruption time to the shortest possible time.</div>
       <div class="text_red">8.3 after prior notification to the user, the company may terminate the services of the platform according to the actual needs of the business. the user agrees that if the company has notified the user in advance of the termination of the service, the company shall not be responsible for any loss caused to the user by the termination of the service.</div>
       <div class="text_msg">8.4 the company may terminate the provision of services to a specific user in the following circumstances:</div>
       <div class="text_msg">(1) the company has the right to terminate the provision of services to the user in the event that the user violates the relevant provisions of laws and regulations or the relevant provisions of this agreement or the rules of the platform. if the user registers again or registers as a user of the platform in the name of another person after being terminated by the company, the company has the right to terminate the provision of services to the user again;</div>
       <div class="text_msg">(3) the user requests the company to terminate the provision of services to the company, and the company agrees;</div>
       <div class="text_msg">(4) other circumstances that shall be terminated in accordance with the agreement of both parties or the provisions of the law.</div>
       <div class="text_msg">8.5 under this agreement, the platform operators may change according to the business adjustments of the platform, and the relevant notices of the platform shall prevail. changes in the operators of the platform will not affect the rights and interests of users under this agreement.</div>
       
       <div class="title_big">article 9 limitation of liability</div>
       <div class="text_msg">9.1 the views, suggestions and other contents expressed by the user on this platform are the views of the user himself and do not represent the views of the company.</div>
       <div class="text_red">9.2 the company shall not be liable for losses caused by force majeure. the force majeure referred to in this service agreement includes: natural disasters, changes in laws and regulations, government directives, third-party application platform reasons, reasons specific to the characteristics of network services (such as failures of domestic and foreign basic telecommunications operators, computer or internet-related technical defects, sudden software and hardware equipment and electronic communication equipment failures, internet coverage limitations, computer viruses, hacker attacks and other factors), and other objective circumstances that cannot be foreseen, avoided and cannot be overcome within the legal scope.</div>
       <div class="text_red">9.3 the user understands and agrees that the services of the platform are provided in accordance with the status quo achievable by the prior technology and conditions. the company will do its best to provide services to you to ensure the consistency and security of the services, but the company cannot foresee and prevent technical risks and other risks at any time, including but not limited to force majeure, viruses, trojan horses, hacker attacks, system instability, third-party service defects, third-party service interference, government actions and other reasons that may cause service interruptions, data loss and other losses and risks. the company cannot ensure that any materials or other data you download from the platform is virus-free, contamination-free or non-destructive.</div>
       <div class="text_red">due to the peculiarity of the network environment and the uncertainty of security factors of the network third parties, the company does not promise that the platform or any content, service or function of the platform is error-free or uninterrupted, and does not promise that any defects will be corrected, or that your use of the platform will produce specific results.</div>
       <div class="text_red">if you are not satisfied with the platform or any content, you should stop using the platform or any such content. the limitation of relief is part of the agreement between the parties.</div>
       <div class="text_red">9.4 huawei shall not be liable for any inconvenience or damage caused by:</div>
       <div class="text_red">(1) in order to better provide users with professional and convenient services and regular inspection of the website and server or construction, update of software and hardware caused by the service interruption;</div>
       <div class="text_red">(2) network service provider line or other faults;</div>
       <div class="text_red">(3) measures taken in an emergency in accordance with the provisions of the law or the instructions of government departments;</div>
       <div class="text_red">(4) disputes between the user and any other third party;</div>
       <div class="text_red">(5) any operation carried out by the company in accordance with the requirements of legal documents and valid government documents that have been in force;</div>
       <div class="text_red">(6) other circumstances caused by reasons other than the company.</div>
       
       <div class="title_big">article 10 notification</div>
       <div class="text_msg">all notices sent by the company to users may be sent by announcement, e-mail or regular forms including but not limited to telephone, sms, easy-trust, wechat, mail, letter, etc. at the same time, the user hereby agrees that the company may send commercial information to its e-mail, telephone, easy-trust, wechat, and mail.</div>
       <div class="title_big">article 11: product description</div>
       <div class="text_msg">Product Name: "Weird West" Equipment Source Description: This set of equipment props is sourced from the official in-game store and obtained through legitimate channels.</div>
       <div class="text_msg">Legal Right Proof: We are officially authorized, and all the props we sell are legally valid.</div>
       <div class="text_msg">Delivery Method: After purchase, we will add the corresponding props directly to your account through the Steam platform. The usual delivery time is within 24 hours after purchase.</div>
       <div class="text_msg">Transaction Terms: All transactions are final and non-refundable once completed. Please read the user agreement carefully before purchasing.</div>

       <div class="title_big">article 12: other agreements</div>
       <div class="text_msg">11.1 if the user has any questions about this agreement, please log on to the platform for relevant information or contact the platform by e-mail [267438914232@qq.com].</div>
       <div class="text_red">11.2 the user understands and agrees that the provision of the services of the platform may be subject to specific constraints or restrictions of third parties for rules including but not limited to application development, operation, transaction, payment, etc. if the user's use of the platform service is invalid or partially invalid due to violation of the rules of a third party, the two parties may refer to the relevant provisions of this agreement to deal with it, if any loss is arising therefrom, the company does not assume any responsibility, and the user agrees not to pursue any form of prosecution with the company. if any provision of this agreement is determined to be fully or partially invalid or unenforceable due to legal provisions, any third-party rules, or effective legal documents of the people's courts, the other provisions of this agreement shall remain valid and enforceable.</div>

       <div class="text_red">11.3 the user understands and agrees that the platform may send you advertisements, promotions or promotional information (including commercial and non-commercial information) on its own or by third-party advertisers in the process of providing services, and the manner and scope of which may be changed without special notice to the user. the user shall judge the authenticity of the advertising information on its own and be responsible for its own judgment, except as expressly provided by law, the platform shall not be liable for the loss or damage suffered by the user due to the transaction based on the advertising information or the content provided by the aforementioned advertiser.</div>
       
  </div>
</template>

<script>
export default {

}
</script>

<style lang='scss' scoped>
.service{
    padding:0 5px;
    margin:0 auto 100px;
    
}
</style>